import React from 'react';

import { ApolloLink } from '@apollo/client';
import { PrinceSdkProvider } from '@teamexos/prince-sdk';

import { clientOptions } from '../apollo/client';

const PrinceSDKProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const extraLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        'x-application-version': `web/${process.env.GATSBY_COMMIT_ID}`,
        'x-application-name': 'shared-platform-admin',
        'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
    return forward(operation);
  });

  return (
    <PrinceSdkProvider
      clientOptions={{
        ...clientOptions,
        extraLink,
      }}
    >
      {children}
    </PrinceSdkProvider>
  );
};

export default PrinceSDKProvider;
