import React from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { SplitFactory, SplitSdk } from '@splitsoftware/splitio-react';
import { AuthProvider } from '@teamexos/fit-shared';

import theme from '../theme';

import PrinceSDKProvider from './PrinceSDK';

const splitFactory = SplitSdk({
  core: {
    authorizationKey: process.env.GATSBY_SPLIT_IO_AUTH_KEY ?? '',
    key: process.env.GATSBY_SPLIT_IO_KEY ?? '',
  },
});

interface Props {
  children?: React.ReactNode;
}

/**
 * P R O V I D E R S
 */
const Providers: React.FC<Props> = ({ children }: Props) => {
  const sharedPlatformDomain = process.env.GATSBY_API_URL;

  if (!sharedPlatformDomain) {
    throw new Error('Missing sharedPlatformDomain');
  }

  return (
    <AuthProvider
      sharedPlatformDomain={sharedPlatformDomain}
      localSignOut={async () => {}}
    >
      <PrinceSDKProvider>
        <SplitFactory factory={splitFactory} updateOnSdkUpdate>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </SplitFactory>
      </PrinceSDKProvider>
    </AuthProvider>
  );
};

export default Providers;
